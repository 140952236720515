$header-color: #EDEDED; // primary color
$light-header-color: #bbbbbb; // primary color
$accent-color: #E27272; // accent color
$text-color: #333; // text color
$light-text-color: #8d8d8d; // text color

.profileContainer {
  border-top: $light-header-color 1px solid;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 66px);
  background-color: $header-color;

  .leftProfileBar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 20%;
    background-color: $light-header-color;
    padding: 15px;
    gap: 10px;
    flex-wrap: wrap;

    .topBar {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 7px;

      .avatarContainer {
        flex-grow: 1;
        display: flex;
        justify-content: flex-end;

        .avatar {
          background-color: white;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          padding: 5px;

        }
      }

      .username {
        flex-grow: 3;
      }

    }

    .middleBar {
      width: 100%;
      // padding: 5px;
      flex-grow: 2;

      .sliderScoreHeader {
        justify-content: space-between;
        align-items: center;

        .scoreQuestionMark {
          border: 1px solid $text-color;
          border-radius: 50%;
          height: 100%;

          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }

        .progressBar {
          color: red;
        }
      }
    }
  }

  .rightProfileBar {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 80%;
    // background-color: aqua;

    .profileViewContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      margin-left: 20px;
      margin-top: 20px;
      max-width: 100%;
      width: 100%;
      overflow: scroll;

      .profileViewNav {
        height: 5%;
        display: flex;
        flex-direction: row;
        background-color: $light-header-color;
        border-radius: 5px;
        gap: 0px;
        padding: 5px;

        button {
          height: 100%;
          border-radius: 0px;
        }
      }

      .profileViewContent {
        // background-color: $light-header-color;
        border-radius: 5px;
        height: 60%;
        width: 95%;


        .activityCards {
          display: flex;
          flex-direction: column;
          // gap: 5px;

          .activityCard {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 5px;
            border-bottom: 1px solid $light-header-color;
            padding: 10px 5px 10px 5px;

            &:hover {
              background-color: $light-header-color;
            }

            .activityCardType {
              background-color: $light-text-color;
              border-radius: 50px;
              padding: 5px;
              width: 30px;
              height: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
            }


            .activityCenterCard {
              flex-grow: 2;

              .activityCardHeader {
                font-weight: bold;
              }

              .activityCardContent {
                color: $light-text-color
              }
            }

            .activityCardDate {
              color: $light-text-color
            }
          }

        }

        .statisticsContainer {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: center;
          gap: 10px;

          .puzzleCardContainer {
            // width: 100%;
            // width: 250px;
            padding: 12px 12px 12px 12px;
            background-color: white;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            height: fit-content;

            &:hover {
              // transform: rotate(-5deg) scale(1.05); // Slight tilt and zoom on hover for effect
              cursor: pointer;
            }

            .dateHeader {
              padding-top: 5px;
              color: #333;
              font-weight: bold;
              padding-bottom: 5px;
            }

            .scoreHeader {
              color: #7f7f7f;
              font-family: 'Helvetica', 'Arial', sans-serif;
              font-size: 14px;
            }

            .scoreDisplay {
              display: flex;
              flex-direction: row;
              gap: 5px;
              align-items: center;
            }
          }
        }

        .uploadViewContainer {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}