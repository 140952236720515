// $header-color: #333; // primary color
$header-color: #EDEDED; // primary color
$accent-color: #E27272; // accent color

.bodyNoScroll {
  overflow: hidden;
  height: 100%;
  /* Optional, to ensure the page height doesn't change */
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

// .App {
//   text-align: center;
// }

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

body {
  margin: 0;

}

.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  // height: 60%;

  .photoContainer {
    display: flex;
    flex-direction: row;
    // width: 80%;

    .photo {
      // width: 50%;

      img {
        max-height: 35vh;
      }

      p {
        text-align: center;
        font-weight: bold;
      }
    }
  }
}


img {
  max-width: 100%;
  width: auto;
}

.main-image {
  grid-area: image;
  // background-image: url("../src/assets/Chai000724-R2-077-37.jpg");
  width: 100%;
  height: 100%;
  background-position: top center;
  background-size: contain;
  background-repeat: no-repeat;
}

.sidebar {
  display: flex;
  flex-direction: row;
}

.sidebar-item {
  display: flex;
  cursor: pointer;
  border: none;
  flex-direction: row;

}

.sidebar-item:hover,
.sidebar-item:focus {
  background-color: hsl(265, 100%, 76%);
}

.sidebar-item.active {
  background-color: hsl(265, 100%, 70%);
}

.sidebar-item::after {
  content: '';
  position: absolute;
  width: 80%;
  left: 10%;
  bottom: 0;
  height: 1px;
}

.sidebar-item:last-child::after {
  display: none;
}


.slider,
.sliderLocked {
  width: 200%;
  cursor: pointer;
  -webkit-appearance: none;
  background: transparent;
}

.sliderLocked {
  opacity: 0.35;
}

.slider::-webkit-slider-runnable-track,
.sliderLocked::-webkit-slider-runnable-track {
  background: rgba(183, 183, 183);
  height: 0.5rem;
  border-radius: 5px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: -4px;
  /* Centers thumb on the track */
  background-color: #f86969;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
}

.sliderLocked::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  margin-top: -4px;
  /* Centers thumb on the track */
  background-color: #848484;
  height: 1rem;
  width: 1rem;
  border-radius: 0.5rem;
}

.slidersContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0px;
  margin-top: 5px;
  margin-bottom: 10px;

  .sliderContainer {
    // margin-left: -45px;
    width: 400px;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;

    p {
      width: 305px;
      color: #000000;
      font-weight: 400;
    }
  }

  .sliderContainerLocked {
    opacity: 0.5;

    input {
      cursor: not-allowed;
    }
  }

}

button {
  width: 100px;
  height: 30px;
  border-radius: 5px;
  border: none;
  background-color: #363636;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: #70737a;
  }
}

.actionButtons {
  gap: 8px;
  display: flex;
  flex-direction: row;

  .resetButton {
    background-color: white;
    border: 2px solid #363636;
    transition: background-color 0.7s, color 0.7s;
    color: #363636;

    &:hover {
      background-color: $accent-color;
      color: white;
    }
  }
}

.modal-overlay {
  position: fixed;
  /* Positioned relative to the viewport */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black */
  z-index: 10;
  /* Ensure it's above other content */
}

//need to change this later when we work with images of different sizes
.comparisonContainer {
  padding-top: 10px;
  // max-width: 400px;
  width: 55%;
  // height: auto;
}

.headerText {
  font-weight: bold;
  font-size: larger;
}

.avatar {
  width: 55px;
  height: 55px;
  object-fit: cover;
  border-radius: 50%;
}

.columnFlex {
  display: flex;
  flex-direction: column;
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.whiteButton {
  background-color: #ffffff;
  border: 2px solid #363636;
  color: black;


  // hover to red 
  &:hover {
    background-color: $accent-color;
    color: white;
  }
}

.redButton {
  background-color: $accent-color;
  color: white;

  // hover to white
  &:hover {
    background-color: #ffffff;
    color: black;
  }
}

.BlackButton {
  &:hover {
    background-color: #70737a;
  }
}


.hoverPointer {
  cursor: pointer;
}