.resultsModal {
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
}

.headerModal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 120px;
}

.buttonsModal {
  display: flex;
  margin: 10px;
  gap: 5px;
  flex-direction: column // align-items: center;

}

#smallText {
  font-size: 13px;
}

// .imagesModal-container {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   width: 400px;
//   max-width: 100%;
// }

// .imagesModal-container img {
//   max-width: 100%;
//   height: auto;
// }

// button {
//   padding: 5px;
//   border-radius: 12px;
// }

// .shareResultsButton {
//   margin-top: 22px;
// }

// .backButton {
//   display: flex;
// }