// $header-color: #333; // primary color
$header-color: #ededed; // primary color
$accent-color: #e27272; // accent color

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 10px 0px 5px 0px;
  background-color: $header-color;
  width: 100%;

  .contentContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
    width: 90%;

    .logoDateContainer {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  h1 {
    color: black;
  }

  img {
    cursor: pointer;
  }
}

.headerNavModal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 10;
  background-color: white;

  button {
    border-radius: 0;
    width: 100%;
    height: 5%;
  }
}

.headerNav {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 910px) {
    width: 100%;
    justify-content: flex-start;
  }

  .profileIcon {
    width: 35px;
    height: 35px;
    cursor: pointer;

    &:hover {
      color: #e27272;
    }
  }

  button {
    margin: 8px;
    height: 35px;
  }
}
.menuButton {
  cursor: pointer;
}
