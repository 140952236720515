.homeContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 2;
  
  .mainMenu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    width: 100vw;
    height: 120vh;
    width: 100vw;

    .homeVideo {
      width: 100vw;
      // height: 100vh;
      background-color: black;
    }

    #Logo {
      height: 300px;
      opacity: 100%;
      margin-top: 55px;
      margin-bottom: 70px;
      margin-right: 0px;
    }

    .buttonContainer {
      display: flex;
      flex-direction: column;
      gap: 15px;

      #Daily {
        font-size: 18px;
        justify-content: center;
        padding: 20px 40px 40px 40px;
        width: 150px;
        font-weight: bold;
        background-color: #E27272;
        transition-duration: 0.4s;
        vertical-align: middle;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease;
      }

      #Daily:hover {
        justify-content: center;
        font-weight: bold;
        background-color: white;
        color: #E27272;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); 
        transform: scale(1.075); 
        text-shadow: 0px 1px 2px rgba(231, 76, 60, 0.5);
      }
      #Scroll {
        font-size: 14px;
        justify-content: center;
        width: 150px;
        font-weight: bold;
        background-color: transparent;
        vertical-align: middle;
        color: white;
        cursor: pointer;
        margin-top: 15px;
      }
    }
  }

  .tutorialText {
    position: relative;
    width: 100vw;
    margin: 0 auto;
    text-align: left;
    padding: 50px 0;
    background-color: rgba(255, 255, 255, 0.75); 
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px); // needed for Safari
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .contentContainer {
      max-width: 680px;
      margin: 0 auto; 
      padding: 0 20px; 
    
      .tutorialHeader {
        flex-shrink: 0;
        color: #000;
        font-size: 40px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        margin-top: 50px;
        margin-bottom: 30px;
      }

      .tutorialHeader2 {
        flex-shrink: 0;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: normal;
        color: #E27272;
        margin-bottom: 10px;
      }

      .tutorialParagraph{
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        margin-top: 10px;
        margin-bottom: 60px;
      }
    }
  }
}

#video{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1; 
  min-width: 100%;
  min-height: 100%;
}