.loginContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.signUpModal {
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 30%;
  text-align: center;
  border-radius: 0px;
}

@media (max-width: 600px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    width: 100%;
  }

  .signUpModal {
    width: 80%;
  }
}

@media (min-width:600px) and (max-width: 1000px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    width: 75%;
  }
}

@media (min-width: 1000px) {
  .formContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 25px;
    width: 100%;
  }
}



form {
  padding: 40px 50px 0px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  img {
    &:hover {
      cursor: pointer;
    }
  }

  input {
    width: 100%;
    height: 50px;
    padding: 5px 20px 5px 20px;
    border: 2px solid #d2d2d2;
    border-radius: 20px;
    font-size: 16px;

    &:hover {
      border: 2px solid #E27272;
    }

    &:focus {
      border: 2px solid #E27272;
      outline: none;
    }
  }

  button {
    width: 100%;
    border-radius: 20px;
    font-size: 16px;
  }


}

.helperContainer {

  .b1 {
    font-weight: 600;
  }

  p {
    text-align: center;
    font-size: 12px;
  }

  padding-bottom: 30px;

  .helperButton {
    background-color: white;
    border: none;
    width: fit-content;
    height: auto;
    color: black;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
  }
}