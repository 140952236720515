.resultsModal {
  z-index: 11;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 10px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-width: 80%;
  text-align: center;
  border-radius: 0px;

  .exitButton {
    position: fixed;
    right: 5px;
    top: 2.5px;
    // display: block;
    cursor: pointer;
    color: black;
    // padding: 10px;
  }
}

@media (max-width: 600px) {

  .resultsModal {
    width: 100%;
    max-width: 100%;
  }
}

.headerModal {
  display: flex;
  flex-direction: column;
  padding-bottom: 7px;

  .section1 {
    max-height: 60px;
  }

  .puzzleTextBlock {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0px;

  }

  .buttonsModal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;

    .tutorialButton {
      background-color: #E27272;
    }
  }
}





.imagesModal-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 400px;
  max-width: 100%;
}

.imagesModal-container img {
  max-width: 100%;
  height: auto;
}

.shareResultsButton {
  margin-top: 22px;
}

.backButton {
  display: flex;
}

.infoTextBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 85%;

  h3 {
    width: 35%;
    text-align: left;
  }

  p {
    text-align: left;

  }
}