// $header-color: #333; // primary color
$header-color: #EDEDED; // primary color
$accent-color: #E27272; // accent color
$text-color: #333; // text color


.tutorialNavContainer {
  display: flex;
  justify-content: center;
  background-color: $header-color;
  height: 30px;
  // padding-top: 5px;
  /* Adjust this to 'start', 'end', 'flex-start', or 'flex-end' if desired */
  // align-items: flex-end;
  position: fixed;
  bottom: 0;
  // gap: 0px;
  width: 100vw;

  .tutorialHeaderNav {
    display: flex;
    // background-color: $header-color;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    width: 90vw;

    .tutorialNavButton {
      text-align: left;
      margin: 0px;
      border-radius: 0px;
      font-size: 14px;
      font-family: 'Roboto', sans-serif;
      // font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      transition: background-color 0.3s, color 0.3s;
      width: calc(100vw / 7);
      height: 40px;
      // margin-right: 10px;
      /* Add margin to the right of each button */
      cursor: default;
      /* Prevents cursor from changing to a pointer */
      padding: 5px;
      // chatGPT
      display: flex;
      /* Enables Flexbox */
      flex-direction: row;

      /* Stacks children vertically */
      justify-content: space-between;

      &:hover {
        background-color: $text-color;
        color: white;
      }
    }

    /* Aligns children (text) to the bottom */
    .currentStage {
      background-color: $header-color;
      color: $text-color;
      border-top: 4px solid #f86969;
      height: 55px;
    }

    .prevStage {
      background-color: $header-color;
      color: $text-color;
      border-top: 4px solid #439E43;
    }

    .upcomingStage {
      background-color: $header-color;
      color: $text-color;
      border-top: 4px solid grey;

      &:hover {
        background-color: $text-color;
        color: white;
        cursor: not-allowed;
      }
    }
  }
}

// .tutorialNavButton:last-child {
//   margin-right: 0;
//   /* Remove margin for the last button */
// }