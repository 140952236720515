.puzzleCardContainer {
  // display: flex;
  max-width: 360px;
  padding: 12px 12px 12px 12px;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: fit-content;
  transition: transform 0.3s;

  &:hover {
    // transform: rotate(-5deg) scale(1.05); // Slight tilt and zoom on hover for effect
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    cursor: pointer;
  }

  .dateHeader {
    padding-top: 5px;
    color: #333;
    font-weight: bold;
    padding-bottom: 5px;
  }

  img {
    width: 355px; // Ensure the photo fills the frame
    display: block; // Remove any default margins/paddings
    height: 250px; // Maintain aspect ratio
    object-fit: cover; // Maintain aspect ratio

  }

  .photoTitleContainer {
    height: fit-content;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* This spreads out the child elements */
    align-items: center;
    /* This vertically centers the child elements in the container */
    width: 100%;


    h2 {
      margin: 0;
      padding: 0;
    }

    #reportContainer {
      margin-left: auto;
    }

  }

  .photoTitle {
    color: #000000;
    // text-align: center;
    // padding: 5px 10px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    padding: 0;
  }


  .scoreHeader {
    color: #7f7f7f;
    // text-align: center;
    // padding: 5px 10px;
    font-family: 'Helvetica', 'Arial', sans-serif;
    font-size: 14px;
  }

  .scoreDisplay {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;
    justify-content: space-between;

    .redLike {
      color: #E27272;

      &:hover {
        color: #7f7f7f;
      }

    }

    .default {
      color: #7f7f7f;

      &:hover {
        color: #E27272;
      }
    }

    .rightSidePuzzleStats {
      display: flex;
      flex-direction: row;
      align-items: center;

      .redLike:hover {
        color: #7f7f7f;
      }
    }
  }
}